<template>
  <div>
    <b-row class="mb-1" align-h="end">
      <b-button
        class="mr-1 mb-1 mb-md-0"
        variant="primary"
        size="sm"
        @click="() => this.$router.push('/budget-expense/add/' + this.id)"
      >
        Add Expense
      </b-button>
      <b-button
        class="mr-1 mb-1 mb-md-0"
        variant="primary"
        size="sm"
        @click="
          () => {
            this.$router.push('/budget-expense/' + this.id);
          }
        "
      >
        View Expense
      </b-button>

      <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        right
        class="mb-1 mb-md-0"
        text="Export"
        variant="outline-primary"
      >
        <b-dropdown-item @click="downloadExcel">Excel</b-dropdown-item>
      </b-dropdown>
    </b-row>

    <b-card no-body>
      <!-- Filters -->
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon icon="LockIcon" size="18" />
          <span class="align-middle ml-50">Filters</span>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row align-v="bottom">
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <!-- <label>Territory</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100 select-size-sm"
              v-model="filters.territory"
              :options="budgetTerritory"
              label="title"
              value="value"
              multiple
              placeholder="Select Territory"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <!-- <label>Category</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100 select-size-sm"
              v-model="filters.category"
              :options="budgetCategory"
              label="title"
              value="value"
              multiple
              placeholder="Select Category"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-button
              class="mr-1"
              @click="getOverview"
              size="sm"
              variant="primary"
              :disabled="!filters.category && !filters.terriorty"
              >Fetch Data</b-button
            >
            <b-button
              @click="
                () => {
                  this.filters = { category: null, territory: null };
                }
              "
              size="sm"
              v-ripple.400="'rgba(0, 207, 232, 0.15)'"
              variant="outline-info"
              :disabled="!filters.category && !filters.terriorty"
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Currency Conversion -->
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon icon="LockIcon" size="18" />
          <span class="align-middle ml-50">Currency Conversion</span>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row align-h="center">
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <!-- <label>Currency</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100 select-size-sm"
              v-model="currency_conversion.currency"
              :options="this.$store.state.master.currency"
              label="lbl_value1"
              value="lbl_id"
              placeholder="Select Currency"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <!-- <label>Rate</label> -->
            <b-form-input
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              size="sm"
              class="w-100"
              type="number"
              v-model="currency_conversion.rate"
              placeholder="Rate"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-button
              class="mr-1"
              @click="convertRate"
              size="sm"
              variant="primary"
              :disabled="
                !currency_conversion.currency && !currency_conversion.rate
              "
              >Show conversion</b-button
            >
            <b-button
              @click="resetConversion"
              size="sm"
              v-ripple.400="'rgba(0, 207, 232, 0.15)'"
              variant="outline-info"
              :disabled="
                !currency_conversion.currency && !currency_conversion.rate
              "
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-table
        :items="overviewItems"
        :fields="visibleOverviewRowFields"
        responsive
        bordered
        :tbody-tr-class="rowClass"
        empty-text="No matching records found"
        show-empty
      >
        <template slot="thead-top" slot-scope="row">
          <tr>
            <th
              :style="
                item.key != 'title' &&
                item.key != 't_budget' &&
                item.key != 't_actual'
                  ? 'text-align: center; background-color: #1f307a;color:white'
                  : 'text-align: center;'
              "
              v-for="(item, index) in overviewData.columns"
              :key="index"
              :colspan="
                item.children && item.children.length > 0
                  ? item.children.filter((field) => field.visible).length
                  : 0
              "
            >
              <b-row align-v="center">
                <b-col cols="6">
                  <p style="font-size: 16px">
                    {{
                      item.key != "title" &&
                      item.key != "t_budget" &&
                      item.key != "t_actual"
                        ? item.title
                        : ""
                    }}
                  </p>
                </b-col>
                <b-col cols="6" class="text-right">
                  <b-dropdown
                    variant="primary"
                    v-if="
                      item.key != 'title' &&
                      item.key != 't_budget' &&
                      item.key != 't_actual'
                    "
                    right
                  >
                    <p
                      v-for="(itemNew, indexFilters) in item.filters"
                      :key="indexFilters"
                      BUDGETED
                      class="dropdown-menu-footer"
                    >
                      <b-form-checkbox
                        :checked="true"
                        @change="getFilter(item, itemNew.text)"
                        class="mr-0 mt-50"
                        name="is-rtl"
                        switch
                        inline
                        >{{ itemNew.text }}</b-form-checkbox
                      >
                    </p>
                  </b-dropdown>
                </b-col>
              </b-row>
            </th>
            <th></th>
          </tr>
        </template>
        <template #head()="row">
          <span>{{ row.field.title }}</span>
        </template>
        <template #cell(title)="row">
          <b-form-checkbox
            :value="row.item.key"
            v-model="selectedBox"
            v-if="row.item.data && row.item.data.length > 0"
            @change="
              openSubCarosel(
                row.item.data,
                row.index,
                row.index + 1,
                row.item.key
              )
            "
            ><span class="vs-label">{{ row.item.title }}</span>
          </b-form-checkbox>
          <span v-else class="vs-label">{{ row.item.title }}</span>
        </template>
        <template #cell()="row">
          <b-form-input
            @input="updateChildData(row.field.key, $event, row.item, row.index)"
            v-if="
              row.field.title.toLowerCase() == 'budgeted' &&
              (row.item.parentKey || row.item.parentKey == '0') &&
              row.item.showEditing == true
            "
            :value="row.value ? row.value : 0"
          />
          <!-- {{ row.item.showEditing }} -->
          <span v-else>{{ row.value ? row.value : 0 }} </span>
        </template>
        <template #cell(actions)="row">
          <div class="text-nowrap">
            <feather-icon
              v-if="
                (row.item.parentKey || row.item.parentKey == '0') &&
                row.item.showEditing == false
              "
              :id="`invoice-row-${row.item.title}-send-icon`"
              icon="EditIcon"
              variant="outline-primary"
              title="Edit"
              class="cursor-pointer mx-1"
              size="16"
              @click="enableEditing(row.index)"
            />
            <!-- <b-tooltip
                        title="Edit"
                        class="cursor-pointer"
                        :target="`invoice-row-${data.item.id}-send-icon`"
                      /> -->
            <b-button
              @click="saveChildData(row.item, row.index)"
              class="mr-1"
              v-if="row.item.showEditing == true"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              save
            </b-button>
            <feather-icon
              v-if="row.item.showEditing == true"
              :id="`invoice-row-${row.item.title}-preview-icon`"
              icon="DeleteIcon"
              variant="outline-primary"
              title="Delete"
              size="16"
              class="cursor-pointer"
              @click="disableEditing(row.index)"
            />

            <!-- <b-tooltip
                        title="Delete"
                        :target="`invoice-row-${data.item.id}-preview-icon`"
                      /> -->
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BFormInput,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BNavItemDropdown,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { labels } from "@/utils/constants";

import {
  GetBudgetOverView,
  SaveBudgetSingle,
  GetBudgetTerritory,
  GetBudgetCategory,
} from "@/apiServices/BudgetServices";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BFormInput,
    ToastificationContentVue,
    vSelect,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BNavItemDropdown,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      overviewFields: [],
      overviewItems: [],
      visibleOverviewRowFields: [],

      //New
      overviewData: [],
      selectedBox: [],
      id: null,
      option: [{ name: "Salman", id: 1 }],
      filters: { category: null, territory: null },
      currency_conversion: { currency: null, rate: null },
      budgetTerritory: [],
      budgetCategory: [],
    };
  },
  beforeMount() {
    this.id = this.$route.params.id;
    this.getOverview();

    this.$store.dispatch("master/setLabelMaster", { key: labels.currency });
    this.getBudgetTerritory();
    this.getBudgetCategory();
  },
  watch: {
    //
  },
  computed: {
    //
  },
  methods: {
    getFilter(item, selected) {
      console.log(item);
      let filterdCol = item.children.filter((x) => {
        return x.title.toLowerCase() == selected.toLowerCase();
      });

      if (filterdCol.length > 0) {
        let indexCol = this.overviewFields.findIndex(
          (y) => y.key == filterdCol[0].key
        );

        if (
          this.overviewFields[indexCol].hasOwnProperty("visible") &&
          this.overviewFields[indexCol]["visible"] == false
        ) {
          this.overviewFields[indexCol]["visible"] = true;
        } else {
          this.overviewFields[indexCol]["visible"] = false;
        }
      }
      this.visibleOverviewRowFields = this.overviewFields.filter((p) => {
        return p.visible == true;
      });
      console.log(this.overviewFields);
    },
    convertRate() {
      if (
        !this.currency_conversion.currency ||
        !this.currency_conversion.rate
      ) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please provide required data",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      this.overviewItems = this.overviewItems.map((item) => ({
        ...item,
        converted_budgeted:
          Number(item.t_budget) * Number(this.currency_conversion.rate),
        converted_actual: 0,
      }));

      this.overviewFields.push(
        {
          label: `${this.currency_conversion.currency.lbl_value1} Budgeted`,
          key: "converted_budgeted",
          title: `${this.currency_conversion.currency.lbl_value1} Budgeted`,
        },
        {
          label: `${this.currency_conversion.currency.lbl_value1} Actual`,
          key: "converted_actual",
          title: `${this.currency_conversion.currency.lbl_value1} Actual`,
        }
      );
    },

    resetConversion() {
      this.overviewItems = this.overviewItems.map((item) => {
        delete item.converted_budgeted;
        delete item.converted_actual;
        return { ...item };
      });
      let tempFields = [];
      this.overviewFields.map((item) => {
        console.log("item => ", item, item.hasOwnProperty("key"));
        if (
          item.key != "converted_budgeted" &&
          item.key != "converted_actual"
        ) {
          tempFields.push(item);
        }
      });
      this.overviewFields = tempFields;
      this.currency_conversion = { currency: null, rate: null };
    },

    async getBudgetTerritory() {
      const response = await GetBudgetTerritory(this.id);
      if (response.data.error)
        return console.log("Error => ", response.data.error);
      this.budgetTerritory = response.data.data;
    },
    async getBudgetCategory() {
      const response = await GetBudgetCategory(this.id);
      if (response.data.error)
        return console.log("Error => ", response.data.error);
      this.budgetCategory = response.data.data;
    },

    async saveChildData(data, index) {
      const response = await SaveBudgetSingle(data);

      if (response.data.error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Failed to update ..!",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Data updated ..!",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.overviewItems[index].showEditing = false;
      }
      console.log("response => ", response);
    },
    updateChildData(key, event, data, index) {
      this.overviewItems[index][key] = event;
      console.log(this.overviewItems[index]);
    },
    enableEditing(index) {
      this.overviewItems[index].showEditing = true;
    },

    disableEditing(index) {
      this.overviewItems[index].showEditing = false;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.parentKey || item.parentKey == "0") return "table-primary";
    },
    openSubCarosel(data, currentIndex, nextIndex, key) {
      let checkAlreadyAdded = this.overviewItems.filter((z) => {
        console.log(z);
        if (z.parentKey || z.parentKey == "0") {
          return z.parentKey == key;
        }
      });
      console.log(this.selectedBox, "checkAlreadyAdded");
      if (checkAlreadyAdded.length > 0) {
        this.overviewItems = this.overviewItems.filter(function (cv) {
          return !data.find(function (e) {
            if (cv.parentKey || cv.parentKey == "0") {
              return e.parentKey == cv.parentKey;
            }
          });
        });
      } else {
        console.log("not Exists");
        this.overviewItems.splice(nextIndex, 0, ...data);
      }
    },
    async getOverview() {
      try {
        let payload = { category: [], territory: [] };
        if (this.filters.territory) {
          this.filters.territory.forEach((element) => {
            payload.territory.push(element.value);
          });
        }
        if (this.filters.category) {
          this.filters.category.forEach((element) => {
            payload.category.push(element.value);
          });
        }

        const response = await GetBudgetOverView(this.id, payload);
        if (response.status == 200) {
          this.overviewData = response.data.data;
          this.overviewItems = response.data.data.rows;

          for (let i = 0; i < this.overviewData.columns.length; i++) {
            let item = this.overviewData.columns[i];
            if (item.children && item.children.length > 0) {
              for (let j = 0; j < item.children.length; j++) {
                let newItem = item.children[j];
                newItem["visible"] = true;
                this.overviewFields.push(newItem);
              }
            } else {
              item["visible"] = true;
              this.overviewFields.push(item);
            }
          }
          let objAction = {
            key: "actions",
            label: "Actions",
            title: "Actions",
            visible: true,
          };
          this.overviewFields.push(objAction);

          this.visibleOverviewRowFields = this.overviewFields;
        }
      } catch (err) {
        console.log("Error => ", err.message);
      }
    },

    async downloadExcel() {
      fetch(this.BASE_URL + "/budget/download?id=" + this.id, {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var _url = window.URL.createObjectURL(blob);
          window.open(_url, "_blank").focus();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
